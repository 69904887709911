import React, {useRef, useState, useEffect} from 'react'
import { graphql, Link } from 'gatsby'

import { isElementInViewport } from '../../components/functions'

export const WhoWeAre = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary;

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const clickDatalayer = (data) => {
        //Data layer - Learn more clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

    return (
      <section className={(isInView)? 'who-we-are pt-5 pt-lg-0 in-view' : 'who-we-are pt-5 pt-lg-0'} ref={ref}>
        <div className='container'>
          <div className='tlt-wrapper slide-up'>
            {(data.section_title.text)?
              <h2>{data.section_title.text}</h2>
            : null}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 img-wrapper overflow-hidden p-0'>
            {(data.section_image.url)?
              <img src={data.section_image.url} alt={(data.section_image.alt)? data.section_image.alt : "Who we are"} width="100%" className='zoom-out-wo-opacity'/>
            : null}
          </div>
          <div className='col-lg-6 pb-5 pb-lg-0'>
            <div className='pl-3 pr-5 content-wrapper slide-up'>
              {(data.section_description)?
                <div className='desc-wrapper' dangerouslySetInnerHTML={{__html: data.section_description.html}} />
              : null}

              {(data.section_link.link_type === "Document" && data.section_link.uid)?
                <Link 
                  to={"/" + data.section_link.uid}
                  onClick={() => {
                    let pageHost = new URL(window.location.href)
                    let pageUrl = pageHost.host;
                    pageUrl +=  (data.section_link.uid === "5v")? "/5V" : "/" + data.section_link.uid;

                    let dl = {
                        'event': 'learn_more_clicked',
                        'page_name': pageUrl,
                        'referral': 'HP',
                        'tile_position': '',
                        'page_location': window.location.href
                    }
                    clickDatalayer(dl)
                  }}
                >
                  <button className='btn btn-primary-3'>
                    <span>{data.section_link_text.text}</span>
                  </button>
                </Link>
                :
                <a
                  href={data.section_link.url}
                  title={data.section_link_text.text}
                  onClick={() => {
                    let dl = {
                      'event': 'learn_more_clicked',
                      'page_name': data.section_link.url,
                      'referral': 'HP',
                      'tile_position': '',
                      'page_location': window.location.href
                    }
                    clickDatalayer(dl)
                  }}
                >
                  <button className='btn btn-primary-3'>
                    <span>{data.section_link_text.text}</span>
                  </button>
                </a>}
            </div>
          </div>
        </div>
      </section>
    )
}

export const query = graphql`
    fragment PageDataBodyWhoWeAreSection on PrismicHomepageDataBodyWhoWeAreSection {
        primary {
            section_description {
              text
              html
            }
            section_image {
              url
              alt
            }
            section_link {
              url
              link_type
              uid
            }
            section_link_text {
              text
            }
            section_title {
              text
            }
        }
    }
`