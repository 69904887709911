import { FeaturedProduct } from './FeaturedProduct'
import { WhoWeAre } from './WhoWeAre'
import { OurBrandsSection } from '../OurBrandsSection'
import { WhereToBuyModule } from '../WhereToBuyModule'
import { WhatsMyWineModule } from '../WhatsMyWineModule'


export const components = {
    featured_product_section: FeaturedProduct,
    our_brands_section: OurBrandsSection,
    who_we_are_section: WhoWeAre,
    where_to_buy_module: WhereToBuyModule,
    whats_my_wine_module: WhatsMyWineModule
}