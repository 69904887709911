//FeaturedProduct.js file

import React, {useRef, useState, useEffect} from 'react'
import { Link, graphql } from 'gatsby'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from 'swiper'

// Import React Icons
import {IoMdArrowDropleft, IoMdArrowDropright} from 'react-icons/io'

// Import Swiper styles
import "swiper/css";

import { isElementInViewport } from '../../components/functions'

SwiperCore.use([Autoplay])
SwiperCore.use([Navigation])

export const FeaturedProduct = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary

    const params = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            noSwiping: false,
        },
        slidesPerView: 2,
        spaceBetween: 40,
        autoplay: {
            delay: 2500,
        },
        loop: (slice.items.length > 2)? true : false,
        navigation: {
            prevEl: '.featured-product .swiper-button-prev',
            nextEl: '.featured-product .swiper-button-next'
        }
    }

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    if(slice.items.length === 0){
        return(<></>)
    }

    return (
        <section className={(isInView)? 'featured-product in-view' : 'featured-product'} ref={ref}>
            <div className='row m-0'>
                <div className='col-lg-6 d-flex justify-content-center align-items-center tlt-wrapper'>
                    <div className='slide-up'>
                        <span className='sub-tlt'>{data.section_subtitle.text}</span>
                        <h2>{data.section_title.text}</h2>
                        <div className='desc' dangerouslySetInnerHTML={{__html: data.section_description.html}} />
                    </div>
                </div>
                <div className='col-lg-6 d-flex justify-content-center align-items-center product-wrapper position-relative'>
                    {/* Featured Products Carousel */}
                    <Swiper className="featured-product-items featured-product-carousel" {...params}>
                        {slice.items.map((item, idx) => (
                            <SwiperSlide className='featured-product-item' key={"featured-product-item-" + idx}>
                                <FeaturedProductItem item={item} idx={idx} key={"featured-product" + idx} />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Swiper Button */}
                    {(slice.items.length > 2)?
                    <div>
                        <div className='swiper-button-prev'>
                            <IoMdArrowDropleft />
                        </div>
                        <div className='swiper-button-next'>
                            <IoMdArrowDropright />
                        </div>
                    </div>
                    : null}
                </div>
            </div>
        </section>
    )
}

const FeaturedProductItem = ({ item, idx }) => {
    const ref = useRef(null)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setWidth(ref.current.offsetWidth)
    }, [])

    return(
        <div className={(idx !== 0)? 'featured-product slide-up animation-delay-' + idx : 'featured-product slide-up'}>
            {/* Image */}
            <div className='bottle-shot d-flex justify-content-center align-items-center'>
                <img src={item.bottle_image.url} height="100%" alt={(item.bottle_image.alt)? item.bottle_image.alt : item.product_title.text} />
            </div>
            <h3 className='font-title mt-4'>{item.product_title.text}</h3>
            <p className='desc mt-3'>{item.product_description.text}</p>
            {(item.product_link)?
                (item.product_link.link_type === "Document" && item.product_link.uid)?
                <Link 
                    to={"/" + item.product_link.uid}
                >
                    {(width !== 0)?
                    <button className='btn btn-primary-1' style={{ width: (width + 10) + "px" }} key="1">
                        <span>{item.product_link_text.text}</span>
                        <span className='btn-hover'>Find where to buy</span>
                    </button>
                    : <button className='btn btn-primary-1' ref={ref} key="2">
                        <span>{item.product_link_text.text}</span>
                        <span className='btn-hover'>Find where to buy</span>
                    </button>}
                </Link>
                :
                <a
                    href={item.product_link.url}
                    target={(item.product_link.target)? item.product_link.target : "_self"}
                >
                    {(width !== 0)?
                    <button className='btn btn-primary-1' style={{ width: (width + 10) + "px" }} key="1">
                        <span>{item.product_link_text.text}</span>
                        <span className='btn-hover'>Find where to buy</span>
                    </button>
                    : <button className='btn btn-primary-1' ref={ref} key="2">
                        <span>{item.product_link_text.text}</span>
                        <span className='btn-hover'>Find where to buy</span>
                    </button>}
                </a>
            : null}
           
        </div>
    )
}

export const query = graphql`
    fragment PageDataBodyFeaturedProductSection on PrismicHomepageDataBodyFeaturedProductSection {
        items {
            product_description {
                text
            }
            product_link_text {
                text
            }
            product_title {
                text
            }
            bottle_image {
                alt
                url
            }
            product_link {
                raw
                size
                slug
                tags
                target
                type
                uid
                url
                link_type
                lang
                isBroken
                id
            }
        }
        primary {
            section_description {
                text
                html
            }
            section_subtitle {
                text
            }
            section_title {
                text
            }
        }
    }
`