import * as React from 'react'

const HomeHero = ( {  title, backgroundUrl } ) => {

  return (
    <section className='in-view home__hero d-flex justify-content-center align-items-center zoom-out-bg-wo-opacity animation-duration-2' style={{backgroundImage: `url(${backgroundUrl})`}}>
        <div className='home__hero-content text-center slide-up d-flex justify-content-center align-items-center'>
          <h2>{title}</h2>
        </div>
    </section>
  )
}

export default HomeHero
