import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/home'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

//components
import HomeHero from '../components/HomeHero'

const Homepage = ({data}) => {
  const [sliceData, setSliceData] = useState([])

  /* REFORMAT DATA FOR SLICEZONE DATA */
  const pageData = data.prismicHomepage.data
  const moduleOurBrand = data.prismicOurBrandsSection.data
  const moduleWhereToBuy = data.prismicWhereToBuyModule.data
  const moduleWhatsMyWine = data.prismicWhatsMyWineModule.data
  const doc = data.prismicHomepage.data

  useEffect(() => {
    /* ADD MODULES AND SECTIONS TO SLICEDATA */
    const newSliceData = data.prismicHomepage.data.body

    // Push our brand module to sliceData array
    var ourBrandSliceData = {
      slice_type: "our_brands_section",
      primary: {
        section_title: moduleOurBrand.section_title,
        section_desc: moduleOurBrand.section_text_field,
        brand_link_image_text_group: moduleOurBrand.brand_link_image_text_group
      }
    }
    var checkOurBrandDuplicateData = newSliceData.map((item) => {
      if(item.slice_type === "our_brands_section") return true;
      return false;
    })
    if(!checkOurBrandDuplicateData.includes(true))  newSliceData.splice(2, 0, ourBrandSliceData)
    // Eof push our brand module to slideData array

    // Push where to buy module
    var whereToBuySliceData = {
      slice_type: "where_to_buy_module",
      primary: {
        section_title : moduleWhereToBuy.section_title,
        section_desc: moduleWhereToBuy.section_description,
        button_link: moduleWhereToBuy.button_link,
        button_link_text: moduleWhereToBuy.button_link_text,
        section_image: moduleWhereToBuy.section_image
      }
    }
    var checkWhereToBuyDuplicateSliceData = newSliceData.map((item) => {
      if(item.slice_type === "where_to_buy_module") return true;
      return false;
    })
    if(!checkWhereToBuyDuplicateSliceData.includes(true))  newSliceData.push(whereToBuySliceData)

    // Eof push where to buy module

    // Push Whats my wine module
    var whatsMyWineSliceData = {
      slice_type: "whats_my_wine_module",
      primary: {
        section_title: moduleWhatsMyWine.section_title,
        section_desc: moduleWhatsMyWine.section_description,
        sub_section: moduleWhatsMyWine.sub_section,
        homepage: true
      }
    }
    var checkWhatsMyWineDuplicateSliceData = newSliceData.map((item) => {
      if(item.slice_type === "whats_my_wine_module") return true;
      return false;
    })
    if(!checkWhatsMyWineDuplicateSliceData.includes(true)) newSliceData.push(whatsMyWineSliceData)
    // Eof push what's my wine module

    setSliceData(newSliceData)

    //Scroll to the top on new page
    document.documentElement.style.scrollBehavior = 'auto'
    window.scrollTo(0,0)
  }, [data.prismicHomepage.data.body, moduleOurBrand.brand_link_image_text_group,
  moduleOurBrand.section_text_field, moduleOurBrand.section_title, moduleWhatsMyWine.section_description, moduleWhatsMyWine.section_title,
  moduleWhatsMyWine.sub_section, moduleWhereToBuy.button_link, moduleWhereToBuy.button_link_text, moduleWhereToBuy.section_description,
  moduleWhereToBuy.section_image, moduleWhereToBuy.section_title])

  return(
    <Layout currentPage={"home"}>
      <Seo
        title={(pageData.meta_title)? pageData.meta_title.text : null}
        description={(pageData.meta_description)? pageData.meta_description.text : null}
      />
      <HomeHero
        title={doc.banner_title.text}
        backgroundUrl={doc.banner_background.url}
      />
      <SliceZone slices={sliceData} components={components} />
    </Layout>
  )
}


export const query = graphql`
  query HomepageQuery {
    prismicHomepage {
      _previewable
      data {
        meta_title {
          text
        }
        meta_description{
            text
        }
        banner_title {
          text
        }
        banner_background {
          url
        },
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyFeaturedProductSection
          ...PageDataBodyWhoWeAreSection
        }
      }
    }
    prismicOurBrandsSection{
      _previewable
      data {
        section_title {
          text
        }
        section_text_field {
          text
        }
        brand_link_image_text_group {
          brand_image {
            alt
            copyright
            url
          }
          brand_link {
            raw
            size
            slug
            tags
            target
            type
            uid
            url
            link_type
            lang
            isBroken
            id
          }
          brand_name {
            richText
            text
            html
            raw
          }
        }
      }
    }
    prismicWhereToBuyModule {
      _previewable
      data {
        section_title {
          text
        }
        section_description {
          text
          html
        }
        button_link {
          raw
          size
          slug
          tags
          target
          type
          uid
          url
          link_type
          lang
          isBroken
          id
        }
        button_link_text {
          text
        }
        section_image {
          alt
          url
        }
      }
    }
    prismicWhatsMyWineModule {
      _previewable
      data {
        section_title {
          text
        }
        section_description {
          text
          html
        }
        sub_section {
          sub_section_title {
            text
          }
          sub_section_description {
            text
            html
          }
          sub_section_image{
            alt
            url
          }
          sub_section_button_text{
            text
          }
          sub_section_button_link{
            raw
            size
            slug
            tags
            target
            type
            uid
            url
            link_type
            lang
            isBroken
            id
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Homepage)